<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';

export default {
	name: "BoAffiliate",
	extends: Base,
	data(){
		return {
			Name:"BoAffiliate",
			mrLevel: {},
			row1:{},
			font_style:{},
			font_size:{},
			sub_font_size:{},
			detail:{name:'',phone:'',website:'',country:'',city:'',zip:'',street:''}
		}
	},
	mounted(){
		this.$set(this.$root, 'page', this)
		this.refreshData()
	},
	watch:{
		'$route.query'(){
			this.refreshData()
		},
		'filter.level'(){
			this.search()
		},
	},
	methods:{
		detailContact(v){
			$('#detail_contact').modal()
			this.detail.id= v.af_id
			this.detail.name= v.af_name
			this.detail.phone= v.af_phone
			this.detail.website= v.af_link_website ? v.af_link_website : '-'
			this.detail.country= v.country
			this.detail.city= v.af_city
			this.detail.street= v.af_address           
			this.detail.zip= v.af_zip_code   
            BOGen.apirest('/'+this.Name, {v,type:'update'}, (err,resp)=>{
            	this.refreshData()
            }, "POST");   
        },
        submitHero(){
	        BOGen.apirest('/'+this.Name, {data:this.row1,type:'updateHero'}, (err,resp)=>{
	            if(resp.error) return Gen.info(resp.message, resp.style,3000,'.hero-info')           
	            if(resp.success) {
	                Gen.info(resp.message, resp.style,2000,'.hero-info') 
	                this.refreshData()
	                setTimeout(()=>{
	                	$('[href="#collapseExample"]').click()
	                },2100)
	            }          
	        }, "POST");
	    },
	}
};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		
		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row">
		<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
	        <div class="card">
	        	<VForm @resp="submitHero" method="post">
	            <div class="card-body">
	                <h5 class="card-title">Hero Image</h5>
	                <div class="row">
	                    <div class="col-md-6">
	                        <div class="wrap_slider_img">
	                            <img :src="uploader(row1.ash_image_desktop)" class="img-responsive" />
	                            <div class="slider_name">
	                                <p>Hero Image</p>
	                            </div>
	                            <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
	                        </div>
	                    </div>
	                </div>
	                <div class="row collapse mt-4" id="collapseExample">
	                    <div class="hero-info col-12"></div>
	                    <div class="col-md-4 mb-3">
	                        <BoField name="ash_image_desktop" mandatory>
	                            <Uploader :param="{thumbnail:true}" name="ash_image_desktop" v-model="row1.ash_image_desktop" type="hero_descktop" uploadType="cropping"></Uploader>
	                        </BoField>
	                        <BoField name="ash_image_mobile" mandatory>
	                            <Uploader :param="{thumbnail:true}" name="ash_image_mobile" v-model="row1.ash_image_mobile" type="hero_mobile" uploadType="cropping"></Uploader>
	                        </BoField>
	                    </div>
	                    <div class="col-md-8 mb-3">
	                        <div class="row">
	                            <div class="col-md-12">
	                                <div class="panel_group">
	                                    <div class="panel_heading">Heading</div>
	                                    <div class="panel_body">
	                                        <div class="row">
	                                            <div class="col-md-9">
	                                              <BoField name="ash_title_en" v-model="row1.ash_title_en"></BoField>
	                                            </div>
	                                            <div class="col-md-9">
	                                                 <BoField name="ash_title_id" v-model="row1.ash_title_id"></BoField>
	                                            </div>
	                                            <div class="col-md-4">
	                                                <div class="form-group">
	                                                    <label class="control-label">Font Style</label>
	                                                    <select class="form-control" v-model="row1.ash_title_font_type">
	                                                        <option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
	                                                    </select>
	                                                </div>
	                                            </div>
	                                            <div class="col-md-2">
	                                                <div class="form-group">
	                                                    <label class="control-label">Font Size </label>
	                                                    <select class="form-control" v-model="row1.ash_title_size">
	                                                        <option v-for="(v,k) in font_size" :key="k" :value="v">{{v}}</option>
	                                                    </select>
	                                                </div>
	                                            </div>
	                                            <div class="col-md-2">
	                                                <BoField :attr="{type:'color'}" name="ash_title_color" v-model="row1.ash_title_color"></BoField>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                            <div class="col-md-12">
	                                <div class="row">
	                                    <div class="col-md-12">
	                                        <div class="panel_group">
	                                            <div class="panel_heading">Sub-Heading</div>
	                                            <div class="panel_body">
	                                                <div class="row">
	                                                    <div class="col-md-9">
	                                                        <div class="form-group">
	                                                            <BoField name="ash_sub_title_en" v-model="row1.ash_sub_title_en"></BoField>
	                                                        </div>
	                                                    </div>
	                                                    <div class="col-md-9">
	                                                        <BoField name="ash_sub_title_id" v-model="row1.ash_sub_title_id"></BoField>
	                                                    </div>
	                                                    <div class="col-md-4">
			                                                <div class="form-group">
			                                                    <label class="control-label">Font Style</label>
			                                                    <select class="form-control" v-model="row1.ash_sub_title_font_type">
			                                                        <option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
			                                                    </select>
			                                                </div>
			                                            </div>
			                                            <div class="col-md-2">
			                                                <div class="form-group">
			                                                    <label class="control-label">Font Size </label>
			                                                    <select class="form-control" v-model="row1.ash_sub_title_size">
			                                                        <option v-for="(v,k) in sub_font_size" :key="k" :value="v">{{v}}</option>
			                                                    </select>
			                                                </div>
			                                            </div>
	                                                    <div class="col-md-2">
	                                                        <div class="form-group">
	                                                            <label class="control-label">Color </label>
	                                                            <input class="form-control" type="color" id="favcolor" name="favcolor" v-model="row1.ash_sub_title_color">
	                                                        </div>
	                                                    </div>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                            <div class="col-4 mt-3">
	                                <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        	</VForm>
	        </div>
	      </div>
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
             <div class="card">
                <div class="card-body">
	                <VForm @resp="search">
						<div class="row">
							<div class="col-sm-5">
								<h5 class="card-title">{{ObjectName}} List</h5>
							</div>
							<div class="col-sm-3">
							</div>
							<div class="col-sm-3">
								<div class="form-group mb-0">
                                    <div class="input-group">
                                       <input type="text" v-model="filter.search"  class="form-control" placeholder="Search...">
                                       <div class="input-group-append">
                                          <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                                       </div>
                                    </div>
                                 </div>
							</div>
							<div class="col-sm-1">
								<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
							</div>
						</div>
					</VForm>
                </div>
                <div class="table-responsive ajax-table">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>#</th>
								<th><SortField name="af_name"></SortField></th>
								<th>No Handphone</th>
								<th>Website</th>
								<th>Country</th>
								<th>City</th>
								<th>Submit Date</th>
								<th width="120px">Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(v,k) in data.data" :key="k">
								<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
								<td>{{v.af_name}}</td>
								<td>{{v.af_phone}}</td>
								<td>{{v.af_link_website ? v.af_link_website : '-'}}</td>
								<td>{{v.country}}</td>
								<td>{{v.af_city}}</td>
								<td>{{(v.af_submit_at).dates('format2')}}</td>
								<td class="btn-action">
									<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									<a href="javascript:;" @click="detailContact(v)" class="text-inverse icon_action act_icon" v-tooltip="'Detail'"><i class="ti-eye"></i></a>
									<a :class="v.af_status=='U'? 'icon_action' : 'icon_action bg-success'" v-tooltip="v.af_status=='U'?'Unread':'Read'"><i class="ti-check"></i></a>
								</td>
							</tr>
							<tr v-if="NotFound">
								<td colspan="99">
									<h3 class="tc">{{NotFound}}</h3>
								</td>
							</tr>
							<tr v-if="data.data===false">
								<td colspan="99">
									<LoadingSpinner light></LoadingSpinner>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="panel-body">
					<div class="pull-right">
						<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
             </div>
          </div>
          <div id="detail_contact" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
			   <div class="modal-dialog">
			      <div class="modal-content">
			         <div class="modal-header">
			            <h4 class="modal-title" id="prod_cat">Detail Affiliate Form</h4>
			            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
			         </div>
			         <div class="modal-body">
			            <div class="row">
			               	<div class="col-md-12 mt-2">
			               		<div class="row">
			               			<div class="col-md-6">
			               				<div class="form-group">
						                    <label class="control-label">Full Name  </label>
						                   	<p>{{detail.name}}</p>
					                  	</div>
						                <div class="form-group">
			                     			<label class="control-label">City </label>
			                     			<p>{{detail.city}}</p>
			                  			</div>
			                  			<div class="form-group">
			                     			<label class="control-label">Zip Code</label>
			                     			<p>{{detail.zip}}</p>
			                  			</div>
			               			</div>
			               			<div class="col-md-6">
			               				<div class="form-group">
					                     	<label class="control-label">No Handphone </label>
					                     	<p>{{detail.phone}}</p>
					                  	</div>
					                  	<div class="form-group">
					                     	<label class="control-label">Country </label>
					                     	<p>{{detail.country}}</p>
			                  			</div>
			               				<div class="form-group">
					                     	<label class="control-label">Street Address </label>
					                     	<p>{{detail.street}}</p>
			                  			</div>
			               			</div>
			               			<div class="col-md-12">
					                  	<div class="form-group">
						                    <label class="control-label">Website </label>
						                    <p>{{detail.website}}</p>
						                </div>
			               			</div>
			               		</div>
			               	</div>			               
			            </div>
			         </div>
			         <div class="modal-footer">
			         	<a class="btn btn-info" :href="'/affiliate/'+detail.id" target="_blank"><i class="fa fa-download"></i> Download</a>
			         </div>
			      </div>
			   </div>
			</div>
       </div>
	</div>
</template>
